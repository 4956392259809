import './assets/css/layout.css'
import './assets/css/illustration.css'

import { useContext, useEffect } from 'react'
import { Link, useRouteError } from 'react-router-dom'
import { mapStackTrace } from 'sourcemapped-stacktrace'

import { Footer } from './components/Footer.tsx'
import GlobalContext from './components/GlobalContext.tsx'
import { Header } from './components/Header.tsx'

export default function ErrorPage() {
  const error: any = useRouteError()
  const { host, org } = useContext(GlobalContext)

  console.error(error.stack)

  useEffect(() => {
    mapStackTrace(error.stack, mappedStack => {
      const body: Record<string, string> = {
        query: 'Error',
        body: `${error.statusText || error.message}
URL: ${window.location.href}
User-Agent: ${navigator.userAgent}
UserID: ${localStorage.getItem('uID') || 'No UserID'}
Version: ${import.meta.env.VITE_BUILD_TIME}
MappedStack: 
${mappedStack?.join('\n')}
Stacktrace:
${error.stack}
        `,
      }
      try {
        if (!host.startsWith('http://localhost')) {
          fetch(`${host}/${org}/error`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          })
        }
      } catch (e) {
        // do nothing
      }
    })
  }, [error.statusText, error.message, error.stack, host, org])

  return (
    <div className={`layout `}>
      <Header />
      <main className="main">
        <div className="container">
          <h3>Oops!</h3>
          <p>Sorry, an unexpected error has occurred. </p>
          <p>
            <b>Try reloading. Sometimes that helps.</b>
          </p>
          <hr />
          <p>Debug info:</p>
          <p>
            <i>{error.statusText || error.message}</i>
          </p>
          <Link to="/">Go to main page</Link>
          {error.stack && (
            <>
              <p>Stacktrace:</p>
              <pre>{error.stack}</pre>
            </>
          )}
        </div>
      </main>
      <div className="fixed-footer">
        <div className="container">
          <Footer />
        </div>
      </div>
    </div>
  )
}
